import React from "react";
import dayjs from "dayjs";
import { differenceInYears } from "date-fns";

//comps
import ChangeProfileRequestDialog from "./ChangeProfileRequestDialog";

//MUI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
//import TableHead from '@mui/material/TableHead';
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
//import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import Button from "@mui/material/Button";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Tooltip from "@mui/material/Tooltip";
//import Box from "@mui/material/Box";

const ProfileTable = ({ UserData, token, setToken }) => {
  // Kostenberechnungsfunktion
  const calculateCosts = (userData) => {
    if (userData.role === "Registriert") {
      return "0 €";
    } else if (userData.model === "Ehrenmitglied-Vereinspflege") {
      return `${parseInt(userData.demand_next_month) * 6} €`;
    } else if (userData.model === "Ehrenmitglied-Privatpflege") {
      return `${parseInt(userData.special_contribution) * 8} €`;
    } else if (
      userData.role === "Mitglied" &&
      userData.current_demand === "" &&
      userData.model === ""
    ) {
      return "25 €";
    } else if (
      userData.role === "Mitglied" &&
      userData.model === "Privatpflege"
    ) {
      return `${parseInt(userData.special_contribution) * 8 + 25} €`;
    } else if (
      
      userData.role === "Mitglied" &&
      userData.model === "Vereinspflege"
    ) {
      return `${
        parseInt(userData.current_demand) * 7 +
        parseInt(userData.special_contribution) * 9 +
        25
      } €`;
    } else if (
      userData.role === "Prime-Mitglied" &&
      userData.model === "Privatpflege"
    ) {
      return `${parseInt(userData.special_contribution) * 8 + 25} €`;
    } else if (
      userData.role === "Prime-Mitglied" &&
      userData.model === "Vereinspflege"
    ) {
      return `${
        parseInt(userData.demand_next_month) * 6 +
        parseInt(userData.special_contribution) * 8 +
        25
      } €`;
    } else {
      return `${
        parseInt(userData.demand_next_month) * 7 +
        parseInt(userData.special_contribution) * 9 +
        25
      } €`;
    }
  };

  const calculateCurrentMonthlyCosts = (userData) => {
    if (userData.role === "Registriert") {
      return "0 €";
    } else if (userData.model === "Ehrenmitglied-Vereinspflege") {
      return `${parseInt(userData.current_demand) * 6} €`;
    } else if (userData.model === "Ehrenmitglied-Privatpflege") {
      return `0 €`;
    } else if (
      userData.current_demand === "" ||
      userData.model === "" ||
      userData.model === "Privatpflege" ||
      (userData.model === "Vereinspflege" && userData.current_demand === "")
    ) {
      return "25 €";
    } else if (userData.role === "Prime-Mitglied") {
      return `${parseInt(userData.current_demand) * 6 + 25} €`;
    }else {
      return `${parseInt(userData.current_demand) * 7 + 25} €`;
    }
  };

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{
          margin: "auto",
          marginTop: 2,
          maxWidth: "100%", // Setze die maximale Breite auf 100%
          overflowX: "auto", // Füge einen horizontalen Scrollbar hinzu, wenn nötig
        }}
      >
        <Table aria-label="simple table">
          <TableBody
            sx={{
              minWidth: "50px",
              maxWidth: "300px",
              "&:last-child td, &:last-child th": { border: 1 },
              "@media screen and (max-width: 1000px)": {
                // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
                fontSize: 10, // Beispiel: Verkleinern Sie die Schriftgröße
                // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
              },
            }}
          >
            <TableRow key="Vorname">
              <TableCell component="th" scope="row">
                Vorname
              </TableCell>
              <TableCell align="right">
                {
                  UserData[0].firstname
                  //hier wird ein cond rendering eingefügt mit den row namen z.b. wenn 'E-Mail Adresse', dann button => leitet auf neue seite zum ändern
                }
              </TableCell>
            </TableRow>

            <TableRow key="Nachname">
              <TableCell component="th" scope="row">
                Nachname
              </TableCell>
              <TableCell align="right">{UserData[0].lastname}</TableCell>
            </TableRow>

            <TableRow key="Geburtsdatum">
              <TableCell component="th" scope="row">
                Geburtsdatum
              </TableCell>
              <TableCell align="right">{UserData[0].date_of_birth}</TableCell>
            </TableRow>

            <TableRow key="E-Mail Adresse">
              <TableCell component="th" scope="row">
                E-Mail Adresse{" "}
                <ChangeProfileRequestDialog
                  changerequest="E-Mail Adresse"
                  token={token}
                  UserData={UserData}
                ></ChangeProfileRequestDialog>
              </TableCell>
              <TableCell align="right">{UserData[0].email}</TableCell>
            </TableRow>

            <TableRow key="Telefonnummer">
              <TableCell component="th" scope="row">
                Telefonnummer{" "}
                <ChangeProfileRequestDialog
                  changerequest="Telefonnummer"
                  token={token}
                  setToken={setToken}
                  UserData={UserData}
                ></ChangeProfileRequestDialog>
              </TableCell>
              <TableCell align="right">{UserData[0].phone_nr}</TableCell>
            </TableRow>

            <TableRow key="Anschrift">
              <TableCell component="th" scope="row">
                Anschrift{" "}
                <ChangeProfileRequestDialog
                  changerequest="Anschrift"
                  token={token}
                  setToken={setToken}
                  UserData={UserData}
                ></ChangeProfileRequestDialog>
              </TableCell>
              <TableCell align="right">
                {UserData[0].street +
                  " " +
                  UserData[0].housenumber +
                  " " +
                  UserData[0].housenumber_add +
                  ", " +
                  UserData[0].zip_code +
                  " " +
                  UserData[0].city}
              </TableCell>
            </TableRow>

            {/*             <TableRow key="Personalausweisnummer">
              <TableCell component="th" scope="row">
                Personalausweisnummer
              </TableCell>
              <TableCell align="right">{UserData[0].passport_ID}</TableCell>
            </TableRow> */}

            <TableRow key="Status">
              <TableCell component="th" scope="row">
                Status{" "}
                {UserData[0].role === "Registriert" ? (
                  <Tooltip title="Registriert bedeutet du bist noch kein Mitglied im Verein">
                    <HelpOutlineOutlinedIcon fontSize="small"></HelpOutlineOutlinedIcon>
                  </Tooltip>
                ) : (
                  ""
                )}
              </TableCell>
              <TableCell align="right">{UserData[0].role}</TableCell>
            </TableRow>

            <TableRow key="Mitgliedsmodel">
              <TableCell component="th" scope="row">
                Mitgliedsmodel{" "}
                <Tooltip
                  title={
                    UserData[0].model === "Privatpflege"
                      ? "Für dich wird kein Cannabis angebaut, du kannst trotzdem, falls vorrätig, welches im Vereinsheim für 9 €/g erwerben."
                      : UserData[0].model === "Vereinspflege"
                      ? "Für dich werden extra Cannabispflanzen angebaut, du kannst deinen Monatsbedarf für 7 €/g einstellen und Zusätzliche Blüten für 9 €/g."
                      : UserData[0].model === "Ehrenmitglied"
                      ? "Du zahlst keinen Basis-Mitgliedsbeitrag."
                      : "Nur möglich für Mitglieder"
                  }
                >
                  <HelpOutlineOutlinedIcon fontSize="small"></HelpOutlineOutlinedIcon>
                </Tooltip>
                <ChangeProfileRequestDialog
                  changerequest="Mitgliedsmodel"
                  token={token}
                  setToken={setToken}
                  role={UserData[0].role}
                  UserData={UserData}
                  membershipModel={UserData[0].model}
                ></ChangeProfileRequestDialog>
              </TableCell>
              <TableCell align="right">{UserData[0].model}</TableCell>
            </TableRow>

            <TableRow key="Mitglied seit">
              <TableCell component="th" scope="row">
                Mitglied seit
              </TableCell>
              <TableCell align="right">
                {UserData[0].member_since &&
                  (() => {
                    const parts = UserData[0].member_since.split(".");
                    if (parts.length === 3) {
                      const [day, month, year] = parts;
                      const formattedDate = new Date(
                        `${year}-${month}-${day}`
                      ).toLocaleDateString("de-DE", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      });
                      return formattedDate;
                    } else {
                      return "Invalid Date Format";
                    }
                  })()}
              </TableCell>
            </TableRow>

            <TableRow key="aktueller Monatsbedarf">
              <TableCell component="th" scope="row">
                aktueller Monatsbedarf{" "}
                <ChangeProfileRequestDialog
                  changerequest="Monatsbedarf"
                  token={token}
                  age={differenceInYears(
                    new Date(),
                    new Date(UserData[0].date_of_birth)
                  )}
                  setToken={setToken}
                  role={UserData[0].role}
                  UserData={UserData}
                ></ChangeProfileRequestDialog>
                <Tooltip
                  title={
                    "Die Änderung wirkt sich nur auf den nächsten Monat aus vom 15. bis zum 15."
                  }
                >
                  <HelpOutlineOutlinedIcon fontSize="small"></HelpOutlineOutlinedIcon>
                </Tooltip>
              </TableCell>
              <TableCell align="right">
                {UserData[0].model === "Privatpflege" || UserData[0].model ==="Ehrenmitglied-Privatpflege"
                  ? ""
                  : UserData[0].model === "Vereinspflege" &&
                    UserData[0].current_demand === ""
                  ? ""
                  : UserData[0].model === "Vereinspflege" || UserData[0].model ==="Ehrenmitglied-Vereinspflege" || UserData[0].model === "Ehrenmitglied"
                  ? UserData[0].current_demand + " g"
                  : ""}
              </TableCell>
            </TableRow>

            <TableRow key="aktuelle monatlichen Kosten">
              <TableCell component="th" scope="row">
                aktuelle monatlichen Kosten
              </TableCell>
              <TableCell align="right">
                {calculateCurrentMonthlyCosts(UserData[0])}
              </TableCell>
            </TableRow>

            <TableRow key="Letzte Änderung des Monatsbedarfs">
              <TableCell component="th" scope="row">
                Letzte Änderung des Monatsbedarfs
              </TableCell>
              <TableCell align="right">
                {dayjs(UserData[0].last_change_demand).format(
                  "DD.MM.YYYY HH:mm:ss"
                ) === "Invalid Date"
                  ? ""
                  : dayjs(UserData[0].last_change_demand).format(
                      "DD.MM.YYYY HH:mm:ss"
                    )}
              </TableCell>
            </TableRow>

            <TableRow key="Monatsbedarf nächster Monat">
              <TableCell component="th" scope="row">
                Monatsbedarf nächster Monat{" "}
              </TableCell>
              <TableCell align="right">
                {UserData[0].model === "Privatpflege"
                  ? ""
                  : (UserData[0].model === "Vereinspflege" &&
                      UserData[0].demand_next_month === undefined) ||
                    (UserData[0].model === "Vereinspflege" &&
                      UserData[0].demand_next_month === "")
                  ? ""
                  : UserData[0].model === "Vereinspflege"
                  ? UserData[0].demand_next_month + " g"
                  : UserData[0].model === "Ehrenmitglied"
                  ? UserData[0].demand_next_month + " g"
                  : ""}
              </TableCell>
            </TableRow>
            <TableRow key="Sonderbeiträge">
              <TableCell component="th" scope="row">
                Sonderbeiträge
              </TableCell>
              <TableCell align="right">
                {UserData[0].role === "Mitglied"
                  ? parseInt(UserData[0].special_contribution) * 9 + " €"  : UserData[0].role === "Prime-Mitglied"
                  ? parseInt(UserData[0].special_contribution) * 8 + " €": "0 €"}
              </TableCell>
            </TableRow>

            <TableRow key="voraussichtliche Kosten nächsten Monat">
              <TableCell component="th" scope="row">
                voraussichtliche Kosten nächsten Monat {" "}
                
                <Tooltip
                  title={
                    "Gilt erst ab möglicher Abgabe von Cannabis, ansonsten 25 €. Abbuchung erfolgt am 15."
                  }
                >
                  <HelpOutlineOutlinedIcon fontSize="small"></HelpOutlineOutlinedIcon>
                </Tooltip>
              </TableCell>
              <TableCell align="right">{calculateCosts(UserData[0])}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProfileTable;
