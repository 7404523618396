import React from "react";
import { styles, smallScreenStyles } from "../styles";
import "../index.css";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Linkleiste from "../components/LinkLeiste.js";
import Logo from "../components/Logo.js";

const Aufklaerung = () => {
  const isScreenSmall = useMediaQuery("(max-width:750px) and (min-width: 0px)");

  const [value, setValue] = React.useState("1");
  const minWindoSize = 500;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <HelmetProvider>
      <div className="page-home">
        <Helmet>
          <title>Aufklärung bei Hemp Heaven Hannover</title>
          <meta
            name="description"
            content="Informiere dich über die Wirkung, Nebenwirkungen und gefahren die mit dem Konsum von Cannabis einhergehen."
          />
          <meta
            name="keywords"
            content="Anbauvereinigung, Hannover, Cannabis, CSC, Cannabis Social Club, Marihuana, Hash, Haschisch, Dope, Weed"
          />
        </Helmet>

        <Box
          component={Paper}
          sx={{
            maxWidth: 800,
            minWidth: 300,
            marginTop: 5,
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
            padding: "2%",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Logo />

          {/* <Text style={styles.titleText}>Hanffarm AV Hannover {"\n"}</Text> */}
          {/*                 <div style={{ textAlign: "center" }}>
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      Momentan nehmen wir keine neuen Mitglieder auf. Ihr könnt
                      euch dennoch auf dieser Website registrieren und werdet
                      dadurch auf eine Warteliste aufgenommen.
                    </span>
                  </div>
                  {"\n"}
                  {"\n"} */}
          <h1
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Gesundheit & Prävention
          </h1>
          <p style={{ ...styles.normalText }}>
            Hier findest du Cannabisspezifische Dateien und Artikel zur
            Aufklärung über die Wirkung, Nebenwirkungen und Gefahren, die mit
            dem Konsum von Cannabis einhergehen
          </p>
          <h3
            style={{
              ...styles.normalText,
              textAlign: "left",
              margin: "0px",
              marginTop: "35px",
            }}
          >
            Cannabis Basisinformationen von der DHS Deutsche Hauptstelle für
            Suchtfragen e.V. (DHS)
          </h3>
          <p style={{ ...styles.normalText }}>
            <a
              href="https://www.dhs.de/fileadmin/user_upload/pdf/Broschueren/Basisinfo_Cannabis.pdf"
              title="Cannabis Basisinformationen"
              style={styles.normalText}
              target="_blank"
              rel="noopener noreferrer"
            >
              Cannabis Basisinformationen
            </a>
          </p>

          <h3
            style={{
              ...styles.normalText,
              textAlign: "left",
              margin: "0px",
              marginTop: "35px",
            }}
          >
            Safer Use Cannabis zum Herunterladen Deutsche Hauptstelle für
            Suchtfragen e.V. (DHS)
          </h3>
          <p style={{ ...styles.normalText }}>
            <a
              href="https://www.dhs.de/fileadmin/user_upload/BZGA-24-05865__Faltblatt_Cannabis_%E2%80%93_Care_Instructions_BFREI.pdf"
              title="Safer Use Cannabis"
              style={styles.normalText}
              target="_blank"
              rel="noopener noreferrer"
            >
              Safer Use Cannabis
            </a>
          </p>

          <h3
            style={{
              ...styles.normalText,
              textAlign: "left",
              margin: "0px",
              marginTop: "35px",
            }}
          >
            Allgemeines Informationsmaterial zum Herunterladen Deutsche
            Hauptstelle für Suchtfragen e.V. (DHS)
          </h3>
          <p style={{ ...styles.normalText }}>
            <a
              href="https://www.dhs.de/infomaterial"
              title="Generelle Informationen"
              style={styles.normalText}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dhs.de/infomaterial
            </a>
          </p>

          <h3
            style={{
              ...styles.normalText,
              textAlign: "left",
              margin: "0px",
              marginTop: "35px",
            }}
          >
            Cannabisprävention der Bundeszentrale für gesundheitliche Aufklärung
            (BZgA)
          </h3>
          <p style={{ ...styles.normalText }}>
            <a
              href="https://www.cannabispraevention.de/"
              title="Cannabisprävention der BZgA"
              style={styles.normalText}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.cannabispraevention.de/
            </a>
          </p>
          <h3
            style={{
              ...styles.normalText,
              textAlign: "left",
              margin: "0px",
              marginTop: "35px",
            }}
          >
            Cannabis Fakten des Deutscher Hanfverbands (DHV)
          </h3>
          <p style={{ ...styles.normalText }}>
            <a
              href="https://cannabisfakten.de/"
              title="Cannabis Fakten des Deutscher Hanfverbands (DHV)"
              style={styles.normalText}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://cannabisfakten.de/
            </a>
          </p>

          <h3
            style={{
              ...styles.normalText,
              textAlign: "left",
              margin: "0px",
              marginTop: "35px",
            }}
          >
            Teste dein Konsumverhalten (5 bis 10 minuten)
          </h3>
          <p style={{ ...styles.normalText }}>
            <a
              href="https://www.drugcom.de/tests/selbsttests/cannabis-check/"
              title="Cannabis Konsum Check"
              style={styles.normalText}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.drugcom.de/tests/selbsttests/cannabis-check/
            </a>
          </p>

          <h3
            style={{
              ...styles.normalText,
              textAlign: "left",
              margin: "0px",
              marginTop: "35px",
            }}
          >
            Suchtberatungsstellen in der Region Hannover
          </h3>
          <p style={{ ...styles.normalText }}>
            <a
              href="https://www.hannover.de/Leben-in-der-Region-Hannover/Gesundheit/Beratungsstellen/Sucht-und-Drogen"
              title="Cannabis Fakten des Deutscher Hanfverbands (DHV"
              style={styles.normalText}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.hannover.de/Leben-in-der-Region-Hannover/Gesundheit/Beratungsstellen/Sucht-und-Drogen
            </a>
          </p>

          <h3
            style={{
              ...styles.normalText,
              textAlign: "left",
              margin: "0px",
              marginTop: "35px",
            }}
          >
            Suchtberatung des Prisma Hannover
          </h3>
          <p style={{ ...styles.normalText }}>
            <a
              href="https://www.prismahannover.de/"
              title="Suchtberatung des Prisma Hannover"
              style={styles.normalText}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.prismahannover.de/
            </a>
          </p>

          {/* <h3
            style={{
              ...styles.normalText,
              textAlign: "left",
              margin: "0px",
              marginTop: "35px",
            }}
          >
            Suchtberatung des Diakonischen Werks Hannover
          </h3>
          <p style={{ ...styles.normalText }}>
            <a
              href="https://www.diakonisches-werk-hannover.de/beratung-leistung/menschen-mit-gesundheitlichen-problemen/suchtberatung/"
              title="Suchtberatung des Diakonischen Werks Hannover"
              style={styles.normalText}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.diakonisches-werk-hannover.de/beratung-leistung/menschen-mit-gesundheitlichen-problemen/suchtberatung/
            </a>
          </p> */}
        </Box>
      </div>
    </HelmetProvider>
  );
};

export default Aufklaerung;
