import React from "react";
import "./Roadmap.css"; // Stil-Datei für die Roadmap

const Roadmap = () => {
  const steps = [
    "Planung",
    "Gründung",
    "Eintragung",
    "Lizenzantrag",
    "Lizenzerlangung",
    "Mietbeginn", 
    "Anbaubeginn",
    <span>Container<br />  Restauration</span>
  ];
  const stepDates = [
    "04.01.2022",
    "13.04.2024",
    "30.05.2024",
    "01.07.2024",
    "18.07.2024",
    "01.10.2024",
    "XX.10.2024",
    "XX.XX.2024"
  ];
  const currentStep = "Lizenzerlangung";


  const totalSteps = steps.length;
  const currentStepIndex = steps.findIndex((step) => step === currentStep);
  const progress = (currentStepIndex / (totalSteps - 1)) * 100;

  return (
    <div className="roadmap-container">
      <div className="roadmap-progress" style={{ width: `${progress}%` }}></div>
      <div className="roadmap-steps">
        {steps.map((step, index) => (
          <div key={index} className="step-container">
            <div
              className={`step ${index <= currentStepIndex ? "completed" : ""}`}
            >
              {step}
            </div>
            <div className="date">{stepDates[index]}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Roadmap;
